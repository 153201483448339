import {
  DefaultResponse,
  GetUserResponse,
  MutationExchangeOneTimeIdTokenArgs,
  MutationInviteUserArgs,
  MutationSetPasswordArgs,
  QueryRequestPasswordResetArgs,
  QueryResendSignupEmailArgs,
} from "../schemaTypes";
import {
  GET_RECOMMENDING_PROVIDERS_BY_ORG_ID,
  GET_USERS,
} from "../Users/queries";
import {
  EXCHANGE_ONE_TIME_ID_TOKEN,
  INVITE_USER,
  SET_PASSWORD,
} from "./mutations";
import { REQUEST_PASSWORD_RESET, RESEND_SIGNUP_EMAIL } from "./queries";

import { useLazyQuery, useMutation } from "@apollo/client";

/**
 * Queries
 */
export const useLazyQueryRequestPasswordReset = () =>
  useLazyQuery<
    { requestPasswordReset: DefaultResponse },
    QueryRequestPasswordResetArgs
  >(REQUEST_PASSWORD_RESET, {
    fetchPolicy: "no-cache",
  });

export const useLazyQueryResendSignupEmail = () =>
  useLazyQuery<
    { resendSignupEmail: DefaultResponse },
    QueryResendSignupEmailArgs
  >(RESEND_SIGNUP_EMAIL);

/**
 * Mutations
 */
export const useMutationInviteUser = () =>
  useMutation<{ inviteUser: GetUserResponse }, MutationInviteUserArgs>(
    INVITE_USER,
    {
      refetchQueries: [GET_USERS, GET_RECOMMENDING_PROVIDERS_BY_ORG_ID],
    }
  );

export const useMutationExchangeOneTimeIdToken = () =>
  useMutation<
    { exchangeOneTimeIdToken: DefaultResponse },
    MutationExchangeOneTimeIdTokenArgs
  >(EXCHANGE_ONE_TIME_ID_TOKEN);

export const useMutationSetPassword = () =>
  useMutation<{ setPassword: DefaultResponse }, MutationSetPasswordArgs>(
    SET_PASSWORD
  );
