export const GroupFields = `
  _id
  title
  endInDays
  endInWeeks
  endInMonths
  endAt
  createdAt
  updatedAt
`;

export const GroupMemberShareFields = `
  _id
  groupId
  organizationId
  subOrganizationId
`;
