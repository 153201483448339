import { memberFields } from "../Members/types";
import { GroupFields, GroupMemberShareFields } from "./types";

import { gql } from "@apollo/client";

export const GET_GROUPS = gql`
  query Groups($organizationId: ID!) {
    groups(organizationId: $organizationId) {
      success
      message
      data {
        accessType
        group {
          ${GroupFields}
        }
      }
    }
  }
`;

export const GET_GROUP_MEMBERS = gql`
  query GroupMembers($groupId: ID!, $organizationId: ID!) {
    groupMembers(groupId: $groupId, organizationId: $organizationId) {
      success
      message
      data {
        ${memberFields}
      }
    }
  }
`;

export const GET_GROUP_MEMBER_SHARES = gql`
  query GroupMemberShares($organizationId: ID!) {
    groupMemberShares(organizationId: $organizationId) {
      success
      message
      data {
        ${GroupMemberShareFields}
      }
    }
  }
`;
